import Login from "./auth/login";
import {AuthProvider} from "./auth/auth";
import {Route, Routes} from "react-router-dom";
import {RequireAuth} from "./auth/RequireAuth";
import Home from "./components/home/home";
import './style.scss'
import Control from "./components/comments/comments";
import 'react-toastify/dist/ReactToastify.css';
import {RequireAdmin} from "./auth/RequireAdmin";
import Moderator from "./components/moderator/moderator";
import Statistika from "./components/statistika/statistika";

function App() {
  return (
    <div className="App">
        <AuthProvider>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route
                    path='/'
                    element={
                        <RequireAuth>
                            <Home />
                        </RequireAuth>
                    }
                >
                    <Route path='' element={<Control/>}/>
                    <Route path='statistika' element={<Statistika/>}/>
                    <Route path='moderator' element={
<RequireAdmin>
                            <Moderator/>
</RequireAdmin>
                    }/>

                </Route>
            </Routes>
        </AuthProvider>
    </div>
  );
}

export default App;
