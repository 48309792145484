import React from 'react';
import {Button, Form, Input} from "antd";
import  './login.scss'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import logo from '../img_1.png'

const Login = () => {
    const navigate=useNavigate();
    const onFinish = (values) => {
axios.post('https://api.info.org.uz/api/v1/login',values).then((res)=>{
    localStorage.clear();
    localStorage.setItem('access',res.data.access)
    localStorage.setItem('role',res.data.role)
    navigate('/')
}).catch((err)=>{
    console.log(err)
    toast.error(err.response.data.message[0], {
        position: toast.POSITION.TOP_RIGHT
    });
})
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div className='login'>
        <div className='form-container'>
            <ToastContainer/>
            <Form
                layout={"vertical"}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <img draggable={false} width={250} src={logo} alt=''/>
                </div>
                <Form.Item
                    className="custom-form-item"
                    style={{margin:0,marginBottom:10}}
                    label="Telefon raqam"
                    name="phone_number"
                    valuePropName={"+998 (__) ___-__-__"}
                    rules={[
                        {
                            required: true,
                            message: "Please input your Phone Number!",
                        },

                    ]}
                >
                    <PhoneInput

                        countryCodeEditable={false}

                        inputProps={{
                            name: 'phone_number',
                            autoFocus: true
                        }}
                        country={'uz'}
                        disableDropdown={true}
                        inputStyle={{width:'100%',height:40}}


                    />
                </Form.Item>

                <Form.Item
                    label="Parol"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password size='large' />
                </Form.Item>



                <Form.Item>
                    <Button size='large' style={{width:'100%'}} type="primary" htmlType="submit">
                        Kirish
                    </Button>
                </Form.Item>
            </Form>
        </div>
        </div>
    );
};

export default Login;