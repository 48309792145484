import React from 'react';
import * as XLSX from 'xlsx';
import {Button} from "antd";

const UserExportButton = ({ data, filename }) => {
    const handleExport = () => {
        const temp=[]
        data?.map((item)=>{
            temp.push({
                Manzil:item.address,
                Ism:item.full_name,
                Profil:item.profile_name,
                Organ:item.organ.name,
                Tarmoq:item.social.name,
                Izoh:item.comment
            })
        })
        const ws = XLSX.utils.json_to_sheet(temp);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };
    return (
        <Button type={"primary"} onClick={handleExport}>Export</Button>
    );
};
export default UserExportButton;


