import React, {useEffect, useState} from 'react';
import {NavLink, Outlet} from "react-router-dom";
import './home.scss'
import {Button, Modal, Popover} from "antd";
import {useAuth} from "../../auth/AuthProvider";
import img from '../../img.png'


const Home = () => {
    const [isModalOpen,setIsModalOpen]=useState(false)
    const {user,getUser}=useAuth()
const openModal=()=>{
    setIsModalOpen(!isModalOpen)
}
const closeModal=()=>{
    setIsModalOpen(false)
}
    useEffect(() => {
        getUser()
    }, []);
    return (
        <div>
            <div className='for-bg'>
                <div className='nav contianer'>
                    <div className='left'>
                        <NavLink style={{marginLeft:'-45px'}} className='nav-link' to={'/'}>
                            <img draggable={"false"} width={100} src={img} alt=''/>
                        </NavLink>
                    </div>
                    <div className='right'>
                        {user?.role==='superadmin'?<NavLink className='nav-link' to='/moderator'>
                            Moderatorlar
                        </NavLink>:<></>

                        }

                        <NavLink className='nav-link' to='/'>
                             Izohlar
                        </NavLink>
                        <NavLink className='nav-link' to='/statistika'>
                             Statistika
                        </NavLink>

                        <Popover
                            title="Malumotnoma"
                            trigger="click"
                            open={isModalOpen}
                            onOpenChange={openModal}
                            content={<>
                                <div className='info-user'>
                                    <span>Ism</span>:<p>{user?.first_name}</p>
                                </div>
                                <div className='info-user'>
                                    <span>Familiya</span>:<p>{user?.last_name}</p>
                                </div>
                                <div className='info-user'>
                                    <span>Otasini ismi</span>:<p>{user?.father_name}</p>
                                </div>
                                <div className='info-user'>
                                    <span>Telefon raqam</span>:<p>+{user?.phone_number}</p>
                                </div>
                                <div className='info-user'>
                                    <span>Role</span>:<p>{user?.role}</p>
                                </div>
                                <div className='info-user'>
                                    <span>Jinsi</span>:<p>{user?.sex==='male'?'Erkak':'Ayol'}</p>
                                </div>
                                <Button className='info-user' danger onClick={()=>{
                                    localStorage.clear();
                                    window.location.href='/'
                                }}>
                                    <span><i className='bx bx-exit'></i></span><p>Chiqish</p>
                                </Button>
                            </>}
                        >
                            <div className='user-circle'>
                                <i className='bx bxs-user' ></i>
                            </div>
                        </Popover>
                    </div>
                </div>
            </div>
            <div className='main'>
                <Outlet/>
            </div>

        </div>
    );
};

export default Home;