import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input, Modal, Popover, Select, Space, Switch, Table, Tag} from "antd";
import axios from "axios";
import {useForm} from "antd/es/form/Form";
import {toast, ToastContainer} from "react-toastify";
import PhoneInput from "react-phone-input-2";


const Moderator = () => {
    const [isOpenModal,setIsOpenModal]=useState(false)
    const [options,setOptions]=useState()
    const [district,setDistrict]=useState()
    const [form]=useForm();
    const [formPass]=useForm();
    const [moderator,setModerator]= useState()
    const [openPassModal,setOpenPassModal]=useState(false)
    const [tempId,setTempIs]=useState()
    const [isLoading,setIsLoading]=useState(false)
    const columns = [
        {
            title: 'Id',
            dataIndex: 'name',
            key: 'name',
            render: (_, __, rowIndex) => rowIndex + 1
        },
        {
            title: 'Ism',
            dataIndex: 'first_name',
            key: 'name',
        },
        {
            title: 'Familiya',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Otasining ismi',
            key: 'father_name',
            dataIndex: 'father_name'
        },
        {
            title: 'Telefon raqam',
            key: 'phone_number',
           dataIndex: 'phone_number'
        },
        {
            title: 'Jinsi',
            key: 'sex',
            dataIndex: 'sex'

        },
        {
            title: 'Viloyat',
            key: 'province',
            render: (_, record) => (
                record.province.name
            ),
        },
        {
            title: 'Tuman',
            key: 'district',
            render: (_, record) => (
                record.district.name
            ),
        },
        {
            title: 'Faolik',
            key: 'district',
            render: (_, record) => (
                <Switch
                    checked={record.status}
                    onChange={(checked) => handleStatusChange(record.id, checked)}
                />
            ),
        },
        {
            title: 'Parol',
            width:'5%',
            key: 'district',
            render: (_, record) => (
                <Popover placement="top" content="Parolni o'zgartirish">
                    <i onClick={()=>openPass(record.id)} style={{fontSize:26,cursor:"pointer"}}  className='bx bx-edit-alt' ></i>
                </Popover>

            ),
        },
    ];

    const handleStatusChange =(id,cheked)=>{

            const updatedData = moderator.map((item) =>
                item.id === id ? { ...item, status: cheked } : item
            );
            axios.patch("https://api.info.org.uz/api/v1/change-status",{
                id:id,
                status:cheked
            },{
                headers:{
                    Authorization:`Bearer ${localStorage.getItem('access')}`
                }
            }).catch((err)=>{
                if(err.response.status===401){
                    localStorage.clear()
                    window.location.href='/login'
                }
            })
            setModerator(updatedData);
        };


    const openModal=()=>{
        setIsOpenModal(true)
    }
    const handleCancel = ()=>{
        setIsOpenModal(false)


    }
    const getProvince = ()=>{
        axios.get('https://api.info.org.uz/api/v1/province',{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            console.log(res)
            const options = res.data.map((temp) => ({
                value: temp.id, label: temp.name
            }));
            setOptions(options)
        })
    }
    const getDistrict=(e)=>{
        console.log(e)
        axios.get(`https://api.info.org.uz/api/v1/district/${e}`,{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            console.log(res)
            const options = res.data.map((temp) => ({
                value: temp.id, label: temp.name
            }));
            setDistrict(options)
        })
    }
    const getModerator = ()=>{
        setIsLoading(true)
        axios.get("https://api.info.org.uz/api/v1/admins",{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            setIsLoading(false)
            setModerator(res.data)
            console.log(res)
        }).catch((res)=>{
            setIsLoading(false)
        })
    }
    useEffect(() => {
        getModerator()
        getProvince()
    }, []);
    const onFinish=(values)=>{
        console.log(values)
        axios.post('https://api.info.org.uz/api/v1/create',values,{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            console.log(res)
            getModerator()
            handleCancel()
            form.resetFields();
        }).catch((err)=>{
            console.log(err)
            toast.error(err.response.data.message[0], {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    }
    const openPass = (id)=>{
        setTempIs(id)
        setOpenPassModal(true)
    }
    const cancelPass = ()=>{
        setOpenPassModal(false)
    }
    const changePassword = (values)=>{
        axios.patch('https://api.info.org.uz/api/v1/change-password',{...values,id:tempId},{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            formPass.resetFields()
            console.log(res)
            cancelPass()
        })
    }
    return (
        <div
            style={{
                padding: 24,
                minHeight: 360,
                height: '90vh',
            }}
        >
            <ToastContainer/>
            <Button onClick={openModal} type={'primary'} style={{marginBottom:10}}>+ Moderator</Button>
            <Table loading={isLoading} columns={columns} dataSource={moderator} pagination={false}/>
            <Modal centered title="Moderator qo'shish" footer={null} open={isOpenModal} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    style={{
                        width:'100%',
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Ism"
                        name="first_name"
                        rules={[
                            {
                                required: true,
                                message:"Ism maydon to'ldirilish kerak!",
                            },
                        ]}
                    >
                        <Input size={"large"} />
                    </Form.Item>
                    <Form.Item
                        label="Familiya"
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: "Familiya maydoni to'ldirish kerak!",
                            },
                        ]}
                    >
                        <Input size={"large"} />
                    </Form.Item>
                    <Form.Item
                        label="Otasini ismi"
                        name="father_name"
                        rules={[
                            {
                                required: true,
                                message: "Maydon to'ldirilishi shart!",
                            },
                        ]}
                    >
                        <Input size={"large"} />
                    </Form.Item>
                    <Form.Item
                        className="custom-form-item"
                        style={{margin:0,marginBottom:10}}
                        label="Telefon raqam"
                        name="phone_number"
                        valuePropName={"+998 (__) ___-__-__"}
                        rules={[
                            {
                                required: true,
                                message: "Please input your Phone Number!",
                            },

                        ]}
                    >
                        <PhoneInput

                            countryCodeEditable={false}

                            inputProps={{
                                name: 'phone_number',
                                autoFocus: true
                            }}
                            country={'uz'}
                            disableDropdown={true}
                            inputStyle={{width:'100%',height:40}}


                        />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password size={"large"} />
                    </Form.Item>


                    <Form.Item
                        label="Jinsi"
                        name="sex"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Select
                            size={"large"}
                            options={[
                                {
                                    value: 'male',
                                    label: 'Erkak',
                                },
                                {
                                    value: 'female',
                                    label: 'Ayol',
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Viloyat"
                        name="province"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Select
                            onChange={getDistrict}
                            size={"large"}
                            options={options}
                        />
                    </Form.Item>


                    <Form.Item
                        label="Tuman"
                        name="district"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Select
                            size={"large"}
                            options={district}
                        />
                    </Form.Item>



                    <Form.Item style={{width:'100%'}}>
                        <Button size={"large"} style={{width:'100%'}} type="primary" htmlType="submit">
                            Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Parolni o'zgartirish" centered open={openPassModal} footer={null} onCancel={cancelPass}>
                <Form form={formPass} layout={"vertical"} onFinish={changePassword}>
                    <Form.Item

                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password size={"large"}/>
                    </Form.Item>

                    <Form.Item
                        name="confirm_password"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password size={"large"}/>
                    </Form.Item>
                    <Form.Item style={{width:'100%'}}>
                        <Button size={"large"} style={{width:'100%'}} type="primary" htmlType="submit">
                            Saqlash
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>

    );
};
export default Moderator;