import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Pagination, Select, Table, Tooltip, Upload} from "antd";
import axios from "axios";
import {PlusOutlined} from "@ant-design/icons";
import { useAuth} from "../../auth/AuthProvider";
import './comments.scss'
import {useForm} from "antd/es/form/Form";
const App = () => {
    const [isOpenModal,setIsOpenModal]=useState(false)
    const [social,setSocial]=useState()
    const [organ,setOrgan]=useState()
    const [fileList, setFileList] = useState([]);
    const {user,getUser}=useAuth()
    const [comments,setComments]=useState()
    const [filter,setFilter]=useState({})
    const [form] =useForm()
    const admin = 'https://api.info.org.uz/api/v1/get/comments/sup'
    const moderator = 'https://api.info.org.uz/api/v1/get/comments/mod'
    const [isLoading,setIsLoading]=useState(false)
    const [amount,setAmount]=useState(0)
    const [current,setCurrent]=useState(1)
    const [tempImg,setTempImg]=useState()
    const [isOpenPic,setIsOpenPic]=useState(false)
    const columns = [
        {
            title: 'Id',
            dataIndex: 'name',
            key: 'name',
            render: (_, __, rowIndex) => rowIndex + 1
        },
        {
            title: 'F.I.SH',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'Manzil',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Tarmoq niki',
            key: 'profile_name',
            dataIndex: 'profile_name',
        },
        {
            title: 'Tarmoq',
            key: 'action',
            render: (_, record) => (
                record.social.name
            ),
        },
        {
            title: 'Organ',
            key: 'action',
            render: (_, record) => (
                record.organ.name
            ),
        },
        {
            title: 'Izoh',
            key: 'comment',
            render: (_, record) => (
                <Tooltip style={{cursor:"pointer"}} placement="top" title={record.comment}>
                    {record.comment.slice(0,25)}{record.comment.length>=20?'...':<></>}
                </Tooltip>
            ),

        },
        {
            title: 'Rasm',
            key: 'action',
            render: (_, record) => (
                <div>
                    {
                    record.comment_user[0]?.image?<div style={{cursor:"pointer"}} onClick={()=>openPic(record.comment_user[0]?.image)}>
                    <img width={'70px'} height={'50px'} src={record.comment_user[0]?.image} alt="Isbot yo'q" />
                </div>:<></>}

                </div>


            ),
        },
        user?.role!=='superadmin'?
        {
            title: "O'chirish",
            key: 'action',
            width:'5%',
            render: (_, record) => (
                <>
                   <Button onClick={()=>deleteCommit(record.id)}>
                       <i className='bx bx-trash'></i>
                   </Button>
                </>
            ),
        }:<></>

    ];
    const deleteCommit =(id)=>{
        axios.patch(`https://api.info.org.uz/api/v1/comments/${id}/delete/`,{id:id},{
            headers:{
                Authorization:`Bearer ${localStorage.getItem("access")}`
            }
        }).then((res)=>{
            getComments()
        }).catch((err)=>{
            console.log(err)
        })
    }
    const openModal=()=>{
        setIsOpenModal(true)
    }
    const onCancel = ()=>{
        setIsOpenModal(false)
    }
    const onChangeS = (values)=>{
        let temp=filter
        if(values!==undefined){
           temp = {
                ...filter,
                social:values
            }
            setFilter(temp)
        }else {
           delete temp.social
        }
        console.log('1',temp)
        setFilter(temp)
        getComments(temp)
    }
    const onChangeO = (values)=>{
        let temp=filter
        if(values!==undefined){
            temp= {
                ...filter,
                organ: values
            }
            setFilter(temp)
        }else {
          delete temp.organ
        }
        console.log('2',temp)
        setFilter(temp)
        getComments(temp)

    }
    const getSocial = ()=>{
        axios.get('https://api.info.org.uz/api/v1/social',{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            console.log(res)
            const options = res.data.map((temp) => ({
                value: temp.id, label: temp.name
            }));
            setSocial(options)
        })

    }
    const getOrgan = ()=>{
        axios.get('https://api.info.org.uz/api/v1/organ',{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            console.log(res)
            const options = res.data.map((temp) => ({
                value: temp.id, label: temp.name
            }));
            setOrgan(options)
        })

    }
    const uploadButton = (
        <div className='addUploader'>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );


    const handlePreview = async (file) => {
    };

    const handleChange = ({fileList: newFileList}) => {
        setFileList(newFileList);
    };
    const onFinish = (values)=>{
        console.log(values)
        const formData = new FormData;
        for (const key in values) {
            if (values.hasOwnProperty(key) && values[key] !== undefined) {
                if (typeof values[key] === 'object') {
                    formData.append(key, values[key].file);
                } else {
                    formData.append(key, values[key]);
                }
            }
        }
        formData.append('moderator',user.id)
        axios.post("https://api.info.org.uz/api/v1/create/comment",formData,{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            console.log(res)
            form.resetFields()
            setFileList([])
            onCancel()
            getComments()
        })
    }



    const getComments=(filter1)=>{
        setIsLoading(true)
        axios.get(localStorage.getItem('role')==='moderator'?moderator:admin, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            },
            params:filter1
        })
            .then((res) => {
                setAmount(res.data.count)
                setIsLoading(false)
                setComments(res.data.results);
                console.log(res);
            })
            .catch((error) => {
                setIsLoading(false)
                if(error.response.status===403){
                    getComments()
                }
                setComments([])
                console.error("Error fetching comments:", error);
            });

    }
    const pagination =(e)=>{
        console.log(e)
        setCurrent(e)
        axios.get(localStorage.getItem('role')==='moderator'?`https://api.info.org.uz/api/v1/get/comments/mod?page=${e}`:`https://api.info.org.uz/api/v1/get/comments/sup?page=${e}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            },
            params:filter
        })
            .then((res) => {
                setAmount(res.data.count)
                setIsLoading(false)
                setComments(res.data.results);
                console.log(res);
            })
            .catch((error) => {
                setIsLoading(false)
                if(error.response.status===403){
                    getComments()
                }
                setComments([])
                console.error("Error fetching comments:", error);
            });
    }
    useEffect(() => {
        getComments()
        getUser()
        getSocial()
        getOrgan()
    }, []);
    const onClosePic = ()=>{
        setIsOpenPic(false)
    }
    const openPic = (temp)=>{
        setTempImg(temp)
        setIsOpenPic(true)
    }
    return (


                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            height:'90vh',
                        }}
                    >
                        <Modal centered width={550} title="Rasm" footer={null} open={isOpenPic} onCancel={onClosePic}>
                            <img width={500} height={300} src={tempImg} alt={""}/>
                        </Modal>
                        <div className='upper-nav'>
                            <div className='add'>
                                {user?.role==='superadmin'?<></>:<>
                                    <Button onClick={openModal} type={"primary"}>
                                        + Izoh
                                    </Button>
                                    <Modal centered title="Comment qo'shish" footer={null} open={isOpenModal} onCancel={onCancel}>
                                        <Form
                                            form={form}
                                            name="basic"
                                            layout={"vertical"}
                                            style={{
                                                width:'100%',
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            // onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Ism"
                                                name="full_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:"Ism maydon to'ldirilish kerak!",
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                label="Profil nomi"
                                                name="profile_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Familiya maydoni to'ldirish kerak!",
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                label="Komentariya"
                                                name="comment"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Maydon to'ldirilishi shart!",
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                label="Manzil"
                                                name="address"
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>



                                            <Form.Item
                                                label="Tarmoq"
                                                name="social"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    size={"large"}
                                                    options={social}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="Organ"
                                                name="organ"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    size={"large"}
                                                    options={organ}
                                                />
                                            </Form.Item>


                                            <Form.Item
                                                label="Rasm"
                                                name="photo"
                                            >
                                                <Upload
                                                    maxCount={1}
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    onPreview={handlePreview}
                                                    onChange={handleChange}
                                                    accept=".png,.jpg,.jpeg"
                                                    beforeUpload={() => false}
                                                >
                                                    {uploadButton}
                                                </Upload>
                                            </Form.Item>


                                            <Form.Item

                                            >
                                                <Button type="primary" htmlType="submit">
                                                    Saqlash
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Modal>
                                </>}

                            </div>

                                <Form  className='filters'>
                                    <Form.Item className={'item'}>
                                        <Select onChange={onChangeS} allowClear placeholder='Tarmoq' options={social} />
                                    </Form.Item>
                                    <Form.Item className='item'>
                                    <Select onChange={onChangeO} allowClear placeholder="Organ" options={organ} />
                                    </Form.Item>
                                </Form>


                        </div>
                        <Table loading={isLoading} columns={columns} dataSource={comments} pagination={false} />
                        <div className='pagination'>
                            {amount>10?
                                <Pagination current={current} onChange={pagination}  simple defaultCurrent={1} total={amount} />
                                :
                                <></>
                            }
                        </div>
                    </div>

    );
};
export default App;