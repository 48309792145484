import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const getUser = () => {
        axios.get('https://api.info.org.uz/api/v1/account/me', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        }).then((res) => {
            setUser(res.data);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching user data: ", error);
            setLoading(false);
        });
    };

    useEffect(() => {
        getUser();
    }, []);

    const logout = () => {
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, loading, getUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export { AuthProvider, useAuth, AuthContext };
