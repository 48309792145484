import React, { useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { Navigate } from "react-router-dom";

const RequireAdmin = ({ children }) => {
  const { user, loading, getUser, logout } = useAuth();
  useEffect(() => {
    getUser();
  }, []);
  if (loading) {
    return <div></div>;
  }
  if (user && user?.role === "superadmin") {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export { RequireAdmin };
