import React, {useEffect, useState} from 'react';
import {Button, Form, Select, Table, DatePicker, Pagination, Modal, Tooltip} from "antd";
import axios from "axios";
import {PlusOutlined} from "@ant-design/icons";
import { useAuth} from "../../auth/AuthProvider";
import './statistika.scss'
import {useForm} from "antd/es/form/Form";
import dayjs from "dayjs";
import UserExportButton from "./excel";
const App = () => {
    const [social,setSocial]=useState()
    const [organ,setOrgan]=useState()
    const { RangePicker } = DatePicker;
    const {user,getUser}=useAuth()
    const [comments,setComments]=useState([])
    const [filter,setFilter]=useState({})
    const admin = 'https://api.info.org.uz/api/v1/get/comments/sup'
    const moderator = 'https://api.info.org.uz/api/v1/get/comments/mod'
    const [isLoading,setIsLoading]=useState(false)
    const [amount,setAmount]=useState(0)
    const [current,setCurrent]=useState(1)
    const [tempImg,setTempImg]=useState()
    const [isOpenPic,setIsOpenPic]=useState(false)
    const columns = [
        {
            title: 'Id',
            dataIndex: 'name',
            key: 'name',
            render: (_, __, rowIndex) => rowIndex + 1
        },
        {
            title: 'F.I.SH',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Tarmoq niki',
            key: 'profile_name',
            dataIndex: 'profile_name',
        },
        {
            title: 'Tarmoq',
            key: 'action',
            render: (_, record) => (
                record.social.name
            ),
        },
        {
            title: 'Organ',
            key: 'action',
            render: (_, record) => (
                record.organ.name
            ),
        },
        {
            title: 'Izoh',
            key: 'comment',
            render: (_, record) => (
                <Tooltip style={{cursor:"pointer"}} placement="top" title={record.comment}>
                    {record.comment.slice(0,25)}{record.comment.length>=20?'...':<></>}
                </Tooltip>
            ),
        },
        {
            title: 'Rasm',
            key: 'action',
            render: (_, record) => (
                <div>
                    {
                        record.comment_user[0]?.image?<div style={{cursor:"pointer"}} onClick={()=>openPic(record.comment_user[0]?.image)}>
                            <img width={'70px'} height={'50px'} src={record.comment_user[0]?.image} alt="Isbot yo'q" />
                        </div>:<></>}

                </div>


            ),
        },

    ];

    const onChangeS = (values)=>{
        let temp=filter
        if(values!==undefined){
           temp = {
                ...filter,
                social:values
            }
            setFilter(temp)
        }else {
           delete temp.social
        }
        console.log('1',temp)
        setFilter(temp)
        getComments(temp)
    }
    const onChangeO = (values)=>{
        let temp=filter
        if(values!==undefined){
            temp= {
                ...filter,
                organ: values
            }
            setFilter(temp)
        }else {
          delete temp.organ
        }
        console.log('2',temp)
        setFilter(temp)
        getComments(temp)

    }
    const getSocial = ()=>{
        axios.get('https://api.info.org.uz/api/v1/social',{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            console.log(res)
            const options = res.data.map((temp) => ({
                value: temp.id, label: temp.name
            }));
            setSocial(options)
        })

    }
    const getOrgan = ()=>{
        axios.get('https://api.info.org.uz/api/v1/organ',{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('access')}`
            }
        }).then((res)=>{
            console.log(res)
            const options = res.data.map((temp) => ({
                value: temp.id, label: temp.name
            }));
            setOrgan(options)
        })

    }

    const getComments=(filter1)=>{
        setIsLoading(true)
        axios.get(localStorage.getItem('role')==='moderator'?moderator:admin, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            },
            params:filter1
        })
            .then((res) => {
                setAmount(res.data.count)
                setIsLoading(false)
                setComments(res.data.results);
                console.log(res);
            })
            .catch((error) => {
                setIsLoading(false)
                if(error.response.status===403){
                    getComments()
                }
                setComments([])
                console.error("Error fetching comments:", error);
            });

    }

    const onChangeD = (values)=>{
        console.log(values)
        let temp = filter;
        if(values!==null){
            const date1 = dayjs(values[0]?.$d);
            const date2 = dayjs(values[1]?.$d);
            const formattedDate1 = date1.format("YYYY-MM-DD");
            const formattedDate2 = date2.format("YYYY-MM-DD");
            temp={
                ...filter,
                to:formattedDate2.toString(),
                from:formattedDate1.toString()
            }

        }else {
            delete temp.to
            delete temp.from
        }
        setFilter(temp)
        getComments(temp)

    }
    const pagination =(e)=>{
        console.log(e)
        setCurrent(e)
        axios.get(localStorage.getItem('role')==='moderator'?`https://api.info.org.uz/api/v1/get/comments/mod?page=${e}`:`https://api.info.org.uz/api/v1/get/comments/sup?page=${e}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            },
            params:filter
        })
            .then((res) => {
                setAmount(res.data.count)
                setIsLoading(false)
                setComments(res.data.results);
                console.log(res);
            })
            .catch((error) => {
                setIsLoading(false)
                if(error.response.status===403){
                    getComments()
                }
                setComments([])
                console.error("Error fetching comments:", error);
            });
    }

    useEffect(() => {
        getComments()
        getUser()
        getSocial()
        getOrgan()
    }, []);
    const onClosePic = ()=>{
        setIsOpenPic(false)
    }
    const openPic = (temp)=>{
        setTempImg(temp)
        setIsOpenPic(true)
    }
    return (
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            height:'90vh',
                        }}
                    >
                        <Modal centered width={550} title="Rasm" footer={null} open={isOpenPic} onCancel={onClosePic}>
                            <img width={500} height={300} src={tempImg} alt={""}/>
                        </Modal>
                        <div className='upper-nav'>
                            <div className='add'>
                                {comments.length!==0?<UserExportButton data={comments} filename='comments'/>:<></>

                                }

                            </div>

                                <Form  className='filters'>
                                    <Form.Item className={'item'}>
                                        <Select onChange={onChangeS} allowClear placeholder='Tarmoq' options={social} />
                                    </Form.Item>
                                    <Form.Item className='item'>
                                    <Select onChange={onChangeO} allowClear placeholder="Organ" options={organ} />
                                    </Form.Item>

                                    <Form.Item className='item'>
                                        <RangePicker onChange={onChangeD} />
                                    </Form.Item>
                                </Form>


                        </div>

                        <Table loading={isLoading} columns={columns} dataSource={comments} pagination={false} />

                        <div className='pagination'>
                            {amount>10?
                                <Pagination current={current} onChange={pagination}  simple defaultCurrent={1} total={amount} />
                                :
                                <></>
                            }
                        </div>

                    </div>

    );
};
export default App;